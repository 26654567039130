<template>
  <section class="iq-style3">
    <b-container class="py-5 text-center">
      <b-row class="d-flex justify-content-center mb-5 p-3">
        <curva-title :title="$t('main.brands')"></curva-title>
      </b-row>
      <b-row class="mb-5 d-flex justify-content-center" cols="3" v-if="topBrands.length > 0">
        <b-col class="d-flex justify-content-center p-3" lg="2" sm="3" v-for="(brand, key) in topBrands" :key="key">
          <router-link :to="{ name: 'brandDetails', params:{id: brand.id} }">
            <div class="mb-4">
              <img class="brand-img mb-1" :src="brand.image">
              <h4 class="text-secondary">{{ brand.name }}</h4>
            </div>
          </router-link>
        </b-col>
      </b-row>
      <b-spinner class="mb-5" v-else variant="warning" type="grow" label="Spinning"></b-spinner>
      <b-row class="d-flex justify-content-center p-3">
        <b-button class="curva-transparent-btn" @click="$router.push({ name:'topBrands'})">
          {{ $t('main.statistics') }}</b-button>
      </b-row>
    </b-container>
  </section>
</template>
<script>
export default {
  props: {
    topBrands: { type: Array }
  }
}
</script>
<style>
.brand-img {
  width: 108px;
}
@media only screen and (max-width: 500px){
  .brand-img {
    width: 90px;
  }
}
</style>
