<template>
<div>
  <section class="cover" v-if="banners.length" :style="{ 'background-image': 'url(' + activeImage + ')' }">
    <!-- {{  activeImage  }} -->
    <div class="overlay">
      <div class="carousel-container">
        <swiper id="home-swiper" :options="swiperOptions" :pagination="true" class="overflow-hidden" @swiper="changeBg">
          <swiper-slide v-for="(i,key) in banners" :key="key">
            <div class="d-flex justify-content-start">
            <span class="tag offer-tag mb-3 d-inline-block">{{ $t('main.offer') }}</span>
            </div>
            <p class="cover-title mb-1 md:mb-4 text-initial">{{ i.title }} </p>
            <div class="d-flex justify-content-start gap_1">
              <router-link v-if="i.more_link" :to="i.more_link">
                <b-button class="curva-transparent-btn">{{ $t('main.more') }}</b-button></router-link>
              <router-link v-if="i.buy_now_link" :to="i.buy_now_link">
                <b-button class="curva-granola-btn rounded-btn ml-3">{{ $t('main.buy') }}</b-button></router-link>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </section>

  <div class="cover" v-else>
    <b-spinner class="mb-5" variant="warning" type="grow" label="Spinning"></b-spinner>
  </div>
</div>
</template>
<script>
export default {
  props: {
    banners: { type: Array }
  },
  data () {
    return {
      activeImage: null,
      swiperOptions: {
        centeredSlides: false,
        loop: true,
        slidesPerView: 1,
        spaceBetween: 0,
        autoplay: {
          delay: 5000, // Change to 5000ms (5 seconds)
          disableOnInteraction: false // Ensure autoplay continues after interaction
        },
        on: {
          slideChange: (data) => {
            // console.log('data -> ', data)
            this.activeImage = this.banners[data.realIndex].image
          }
        },
        breakpoints: {
          // when window width is >= 480px
          480: {
            slidesPerView: 1,
            spaceBetween: 15
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 1,
            spaceBetween: 30
          }
        },
        // If we need pagination
        pagination: {
          el: '.swiper-pagination'
        }
      },
      homeCarouselImage: require('@/assets/images/curva/main-cover.png'),
      slide: 0,
      sliding: null
    }
  },
  methods: {
    changeBg (data) {
      console.log('data => ', data)
    }
  }
}
</script>
<style>
@media only screen and (max-width: 500px) {
  .cover, .cover-image{
    min-height: 60vh !important;
    position: relative;
  }
  .carousel-container {
    padding: 0px !important;
    left: 6%;
  }
  .cover-title {
    font-size: 20px;
  }
  .cover-text {
    font-size: 15px;
  }
  .curva-transparent-btn, .curva-granola-btn {
    height: 40px !important;
    padding: 4px 15px !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    border-radius: 10px !important;
  }
  .offer-tag {
    background-color: var(--iq-blush);
    color: white;
  }
}

</style>
